import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import SponsoredService from "../../services/sponsored";
import { useEffect, useState } from "react";
import Toast from "../../components/toast/Toast";
import columnsHeader from "./ColumnsHeaderData";
import SponsoredHistoryModal from "./SponsoredHistoryModal";
import Loader from "../../components/spinner/Spinner";
import Backdrop from "../../components/backdrop/Backdrop";

const Sponsored = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [backdrop, setBackdrop] = useState(false);
  const [isSidebar, setIsSidebar] = useState(null);
  const [sponsorHistoy, setSponsorHistoy] = useState([]);
  const [rowDataHistoySponsor, setRowDataHistoySponsor] = useState([]);
  const [openHistoy, setOpenHistoy] = useState(false);

  useEffect(() => {
    getAllSponsor();
  }, []);

  const getAllSponsor = async () => {
    setLoading(true);
    try {
      let { data } = await SponsoredService.fetchSponsor();
      console.log("zzzzzzzzzzzzz", data);
      let dupMentorIds = data.data.reduce((a, b) => {
        a[b.kid_id] = ++a[b.kid_id] || 0;
        return a;
      }, {});
      setSponsorHistoy(data.data.filter((p) => dupMentorIds[p.kid_id]));
      // setRowData([...new Map(data.data.map((d) => [d["kid_id"], d])).values()]);
      setRowData(
        Object.values(
          data.data
            .sort(
              (a, b) =>
                new Date(b.mentored_on_date) - new Date(a.mentored_on_date)
            ) // Sort by latest mentored_on_date
            .reduce((acc, curr) => {
              // Only keep the most recent record for each kid_id
              if (!acc[curr.kid_id]) {
                acc[curr.kid_id] = curr;
              }
              return acc;
            }, {})
        )
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setRowData([]);
    }
  };

  const handleDelete = async ({ id, kid_id }) => {
    setBackdrop(true);
    try {
      await SponsoredService.deleteSponsor({ id, kid_id });
      setToastOpen(true);
      setToastMsg("Sponsored deleted successfully");
      setToastStatus("success");
      setOpen(false);
      setBackdrop(false);
      getAllSponsor();
    } catch (err) {
      setToastOpen(true);
      setToastMsg("Something went wrong!");
      setToastStatus("error");
      console.log(err);
      setBackdrop(false);
    }
  };

  const onHistoy = (kid_id) => {
    setOpenHistoy(true);
    if (kid_id) {
      setRowDataHistoySponsor(sponsorHistoy.filter((k) => kid_id == k.kid_id));
    }
  };

  return (
    <div className="list">
      <Sidebar isSidebar={isSidebar} />
      <div className="listContainer">
        <Navbar isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        {loading ? (
          <Loader />
        ) : (
          <Datatable
            pageTitle={"Sponsored"}
            columnsData={columnsHeader.columnsData}
            actionColumn={columnsHeader.actionHeader(
              handleDelete,
              onHistoy,
              sponsorHistoy
            )}
            rowData={rowData}
          />
        )}
      </div>
      {toastOpen && (
        <Toast
          toastOpen={toastOpen}
          toastClose={() => setToastOpen(false)}
          toastMsg={toastMsg}
          toastStatus={toastStatus}
        />
      )}
      {openHistoy && (
        <SponsoredHistoryModal
          open={openHistoy}
          onClose={() => setOpenHistoy(false)}
          rowData={rowDataHistoySponsor}
        />
      )}
      {backdrop && <Backdrop />}
    </div>
  );
};

export default Sponsored;
