import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ChildCareOutlinedIcon from "@mui/icons-material/ChildCareOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";

const Widget = ({ data }) => {
  // let data;

  let renderIcons = () => {
    let Icon = null;
    if (data.table_name === "tbl_users") {
      Icon = <PersonOutlinedIcon style={{ width: 80, height: 80 }} />;
    } else if (data.table_name === "tbl_kid") {
      Icon = <ChildCareOutlinedIcon style={{ width: 80, height: 80 }} />;
    } else if (data.table_name === "tbl_category") {
      Icon = <CategoryOutlinedIcon style={{ width: 80, height: 80 }} />;
    }
    return Icon;
  };

  let bgColor = () => {
    let bg = "";
    if (data.table_name === "tbl_users") {
      bg = "#fdc513";
    } else if (data.table_name === "tbl_kid") {
      bg = "#e3b111";
    } else if (data.table_name === "tbl_category") {
      bg = "#fdc513";
    }
    return bg;
  };

  return (
    <div className="widget" style={{ background: bgColor(), color: "#fff" }}>
      <div className="left">
        <span style={{ textTransform: "capitalize" }}>
          {data.table_name.replace("tbl_", "")}
        </span>
        <span className="counter">
          <b>{data.count}</b>
        </span>
      </div>
      <div className="right">{renderIcons()}</div>
    </div>
  );
};

export default Widget;
